import { createSelector } from "reselect";

export const selectCartSlice = createSelector(
  [(state) => state.cart] /* input selectors */,
  (cart) => cart /* output selector */
);

export const selectCartIsOpen = createSelector(
  [selectCartSlice] /* input selectors */,
  (cart) => cart.cartIsOpen /* output selector */
);

export const selectCartItems = createSelector(
  [selectCartSlice] /* input selectors */,
  (cart) => cart.cartItems /* ouput selector */
);

export const selectCartTotalCount = createSelector(
  /* input selectors */
  [selectCartItems],
  /* output selector */
  (cartItems) => cartItems.reduce((total, item) => total + item.quantity, 0)
);

export const selectCartTotalPrice = createSelector(
  /* input selectors */
  [selectCartItems],
  /* output selector */
  (cartItems) =>
    cartItems.reduce((total, item) => total + item.quantity * item.price, 0)
);
