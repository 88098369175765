import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  query,
  writeBatch,
} from "firebase/firestore";

// TODO: How do we keep this out of git?  They say not to worry about this.
// How foolish - anyone with this data can do it.
const firebaseConfig = {
  apiKey: "AIzaSyAu4muSLwG8dX73k35-4s8U-ptr7PgEbIw",
  authDomain: "crwn-clothing-db-b9c9a.firebaseapp.com",
  projectId: "crwn-clothing-db-b9c9a",
  storageBucket: "crwn-clothing-db-b9c9a.appspot.com",
  messagingSenderId: "530078350935",
  appId: "1:530078350935:web:aa842547fe2b39f9a12d12",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const firebaseAuth = getAuth(firebaseApp);

export const signInWithGooglePopup = function () {
  return signInWithPopup(firebaseAuth, googleProvider);
};

export const firestoreDb = getFirestore();

export const createUserDocumentFromAuth = async function (
  userAuth,
  additionalInfo = {}
) {
  const userDocRef = doc(firestoreDb, "users", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName: displayName,
        email: email,
        createdAt: createdAt,
        ...additionalInfo,
      });
    } catch (error) {
      console.log(error);
    }
  }

  return userSnapshot;
};

export const createNewAuthUser = async function (email, password) {
  return createUserWithEmailAndPassword(firebaseAuth, email, password);
};

export const signInAuthUserWithEmailPassword = async function (
  email,
  password
) {
  return await signInWithEmailAndPassword(firebaseAuth, email, password);
};

export const signOutUser = async () => await signOut(firebaseAuth);

export const onAuthStateChangedListener = (callback) => {
  return onAuthStateChanged(firebaseAuth, callback);
};

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd,
  keyField
) => {
  const collectionRef = collection(firestoreDb, collectionKey);
  const batch = writeBatch(firestoreDb);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object[keyField].toLowerCase());
    batch.set(docRef, object);
  });

  await batch.commit();
};

export const getCategoriesAndItems = async () => {
  const collectionRef = collection(firestoreDb, "categories");
  const querySnapshot = await getDocs(query(collectionRef));
  const categoriesArray = querySnapshot.docs.map((docSnapshot) =>
    docSnapshot.data()
  );
  return categoriesArray;
};

/**
 * Converts from the onAuthStateListner to a promise based function call.
 * So, ought to return a promise.
 */
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      firebaseAuth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};
