import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  startGoogleUserSignIn,
  startEmailPasswordUserSignIn,
  selectSignInError,
} from "../../state/user/user.public";
import FormInput from "../form-input/form-input";
import FormError from "../form-error/form-error";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button";

import "./sign-in-form.scss";

const SignInForm = () => {
  const dispatch = useDispatch();
  const signInError = useSelector(selectSignInError);
  const defaultFormFields = {
    email: "",
    password: "",
  };

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const handleGoogleSignin = () => {
    dispatch(startGoogleUserSignIn());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { email, password } = formFields;
    dispatch(startEmailPasswordUserSignIn(email, password));
    resetFormFields();
  };

  return (
    <div className="sign-in-container">
      <h2>I already have an account</h2>
      <span>Sign-in with your email and password</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          required
          type="text"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <FormInput
          label="Password"
          required
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
        />
        <FormError errorText={signInError} />
        <div className="buttons-container">
          <Button type="submit">Sign In</Button>
          <Button
            buttonType={BUTTON_TYPE_CLASSES.google}
            type="button"
            onClick={handleGoogleSignin}
          >
            Google Sign-In
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SignInForm;
