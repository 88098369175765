import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectCartIsOpen } from "../../state/cart/cart.selector";
import CartIcon from "../../components/cart-icon/cart-icon";
import CartDropdown from "../../components/cart-dropdown/cart-dropdown";

import { ReactComponent as CrownLogo } from "../../assets/crown.svg";
import {
  selectCurrentUser,
  startSignOutUser,
} from "../../state/user/user.public";

import {
  LogoLink,
  NavigationDiv,
  NavLinksDiv,
  NavLink,
} from "./navigation.styles";

const Navigation = function () {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const cartIsOpen = useSelector(selectCartIsOpen);

  const signOutHandler = () => {
    dispatch(startSignOutUser());
  };

  return (
    <Fragment>
      <NavigationDiv>
        <LogoLink className="logo-container" to="/">
          <CrownLogo className="logo" />
        </LogoLink>
        <NavLinksDiv>
          <NavLink className="nav-link" to="/shop">
            SHOP
          </NavLink>
          {currentUser !== null ? (
            <NavLink as="span" onClick={signOutHandler}>
              SIGN-OUT
            </NavLink>
          ) : (
            <NavLink className="nav-link" to="/auth">
              SIGN-IN
            </NavLink>
          )}
          <CartIcon />
        </NavLinksDiv>
        {cartIsOpen && <CartDropdown />}
      </NavigationDiv>
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
