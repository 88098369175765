import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { createAction } from "../../utils/reducer";
import { userActionTypes } from "./user.types";

/** ACTION CREATORS */

export const setCurrentUser = (user) =>
  createAction(userActionTypes.SET_CURRENT_USER, user);

export const checkUserSession = () =>
  createAction(userActionTypes.CHECK_USER_SESSION);

export const startGoogleUserSignIn = () =>
  createAction(userActionTypes.GOOGLE_SIGN_IN_START);

export const startEmailPasswordUserSignIn = (email, password) =>
  createAction(userActionTypes.EMAIL_SIGN_IN_START, {
    email,
    password,
  });

export const userSignInSucceeded = (user) =>
  createAction(userActionTypes.SIGN_IN_SUCCESS, user);

export const userSignInFailed = (error) =>
  createAction(userActionTypes.SIGN_IN_FAILURE, error);

export const startSignOutUser = () =>
  createAction(userActionTypes.SIGN_OUT_START);

export const userSignOutSucceeded = () =>
  createAction(userActionTypes.SIGN_OUT_SUCCESS);

export const userSignOutFailed = (error) =>
  createAction(userActionTypes.SIGN_OUT_FAILURE, error);

export const startUserSignUp = (email, password, displayName) =>
  createAction(userActionTypes.SIGN_UP_START, {
    email,
    password,
    displayName,
  });

export const userSignUpSucceeded = (user, additionalInfo) =>
  createAction(userActionTypes.SIGN_UP_SUCCESS, { user, additionalInfo });

export const userSignUpFailed = (error) =>
  createAction(userActionTypes.SIGN_UP_FAILURE, error);

/** SELECTORS AND DERIVED HOOKS */

const selectUserSlice = createSelector([(state) => state.user], (user) => user);

export const selectCurrentUser = createSelector(
  [selectUserSlice],
  (user) => user.currentUser
);

export const selectSignInError = createSelector(
  [selectUserSlice],
  (user) => user.error
);

export const selectSignUpError = createSelector(
  [selectUserSlice],
  (user) => user.signUpError
);

export const useCurrentUser = () => useSelector(selectCurrentUser);

export const useSignInError = () => useSelector(selectSignInError);
