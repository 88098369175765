import "./form-input.scss";

const FormInput = ({ label, ...otherProps }) => {
  const extraLabelClass = otherProps.value.length > 0 ? "shrink" : "";
  return (
    <div className="group">
      <input className="form-input" {...otherProps} />
      {label && (
        <label className={`${extraLabelClass} form-input-label`}>{label}</label>
      )}
    </div>
  );
};

export default FormInput;
