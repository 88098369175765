import { useDispatch, useSelector } from "react-redux";
import {
  addProductToCartItems,
  removeProductFromCartItems,
  clearProductFromCartItems,
} from "../../state/cart/cart.actions";
import { selectCartItems } from "../../state/cart/cart.selector";
import "./checkout-item.scss";

const CheckoutItem = ({ item }) => {
  const { name, quantity, imageUrl, price } = item;
  const cartItems = useSelector(selectCartItems);
  const dispatch = useDispatch();

  const reduceQuantity = () => {
    dispatch(removeProductFromCartItems(cartItems, item));
  };
  const increaseQuantity = () => {
    dispatch(addProductToCartItems(cartItems, item));
  };
  const removeItem = () => {
    dispatch(clearProductFromCartItems(cartItems, item));
  };

  return (
    <div className="checkout-item-container">
      <div className="image-container">
        <img src={imageUrl} alt={`product ${name} in shopping cart`} />
      </div>
      <div className="name">
        <h3>{name}</h3>
      </div>
      <div className="quantity">
        <span className="arrow" onClick={reduceQuantity}>
          &#10094;
        </span>
        <span className="value">{quantity}</span>
        <span className="arrow" onClick={increaseQuantity}>
          &#10095;
        </span>
      </div>
      <div className="price">{`$${price}`}</div>
      <div className="remove-button">
        <span onClick={removeItem}>&#10005;</span>
      </div>
    </div>
  );
};

export default CheckoutItem;
