import { createSelector } from "reselect";
import { useSelector } from "react-redux";

import { createAction } from "../../utils/reducer";
import { addCollectionAndDocuments } from "../../utils/firebase";

import { categoryActions } from "./category.types";

import SHOP_DATA from "../../data/shop-data";

// This was for loading test data into the database
export const loadData = async () => {
  return await addCollectionAndDocuments("categories", SHOP_DATA, "title");
};

/** ACTION CREATORS */

export const fetchCategoriesStart = () =>
  createAction(categoryActions.FETCH_CATEGORIES_START);

export const fetchCategoriesSuccess = (categoriesArray) =>
  createAction(categoryActions.FETCH_CATEGORIES_SUCCESS, categoriesArray);

export const fetchCategoriesError = (error) =>
  createAction(categoryActions.FETCH_CATEGORIES_FAILED, error);

/** SELECTORS AND DERIVED HOOKS */

const selectCategoriesSlice = createSelector(
  [(state) => state.categories],
  (categories) => categories
);

/**
 * Use of reselect library to create re-renders - memorizes just the categories
 * array from state.  Sort of overkill in this case sicne we could have reshaped
 * in our reducer without loss of generalizty.
 */
export const selectCategoriesMap = createSelector(
  /* array of inpuyt selectors */
  [selectCategoriesSlice],
  /* ouput selector only runs if the output has changed */
  (categoriesObj) =>
    categoriesObj.categories.reduce((accum, category) => {
      const { title, items } = category;
      accum[title.toLowerCase()] = items;
      return accum;
    }, {})
);

export const selectCategoriesLoading = createSelector(
  [selectCategoriesSlice],
  (categoriesObj) => categoriesObj.isLoading
);

/* derived hook */
export const useCategoriesMap = () => useSelector(selectCategoriesMap);
