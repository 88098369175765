import { useSelector, useDispatch } from "react-redux";

import { ReactComponent as ShoppingIcon } from "../../assets/shopping-bag.svg";
import "./cart-icon.scss";
import {
  selectCartIsOpen,
  selectCartTotalCount,
} from "../../state/cart/cart.selector";
import { setCartIsOpen } from "../../state/cart/cart.actions";

const CartIcon = () => {
  const dispatch = useDispatch();
  const totalQuantity = useSelector(selectCartTotalCount);
  const cartIsOpen = useSelector(selectCartIsOpen);

  const toggleCartIsOpen = () => dispatch(setCartIsOpen(!cartIsOpen));

  return (
    <div className="cart-icon-container" onClick={toggleCartIsOpen}>
      <ShoppingIcon className="shopping-icon" />
      <span className="item-count">{totalQuantity}</span>
    </div>
  );
};

export default CartIcon;
