import { takeLatest, all, call, put } from "redux-saga/effects";

import { categoryActions } from "./category.types";
import { getCategoriesAndItems } from "../../utils/firebase";

import {
  fetchCategoriesSuccess,
  fetchCategoriesError,
} from "./category.public";

export function* fetchCategoriesAsync() {
  try {
    const categoriesArray = yield call(getCategoriesAndItems, "categories");
    yield put(fetchCategoriesSuccess(categoriesArray));
  } catch (error) {
    yield put(fetchCategoriesError(error));
  }
}

export function* onFetchCategories() {
  yield takeLatest(
    categoryActions.FETCH_CATEGORIES_START,
    fetchCategoriesAsync
  );
}

export function* categoriesSaga() {
  yield all([call(onFetchCategories)]);
}
