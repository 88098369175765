import { all, call, put, takeLatest } from "redux-saga/effects";

import { userActionTypes } from "./user.types";
import {
  userSignInSucceeded,
  userSignInFailed,
  userSignOutSucceeded,
  userSignOutFailed,
  userSignUpSucceeded,
  userSignUpFailed,
} from "./user.public";

import {
  createNewAuthUser,
  createUserDocumentFromAuth,
  getCurrentUser,
  signInWithGooglePopup,
  signInAuthUserWithEmailPassword,
  signOutUser,
} from "../../utils/firebase";

export function* getSnapshotFromUserAuth(userAuth, additionalInfo) {
  try {
    const userSnapshot = yield call(
      createUserDocumentFromAuth,
      userAuth,
      additionalInfo
    );
    yield put(
      userSignInSucceeded({ id: userSnapshot.id, ...userSnapshot.data() })
    );
  } catch (error) {
    yield put(userSignInFailed(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield call(getCurrentUser);
    if (userAuth === null) return;
    yield call(getSnapshotFromUserAuth, userAuth);
  } catch (error) {
    yield put(userSignInFailed(error));
  }
}

export function* signInWithGoogle() {
  try {
    const { user } = yield call(signInWithGooglePopup);
    yield call(getSnapshotFromUserAuth, user);
  } catch (error) {
    yield put(userSignInFailed(error));
  }
}

export function* signInWithEmail(action) {
  const { email, password } = action.payload;
  try {
    const { user } = yield call(
      signInAuthUserWithEmailPassword,
      email,
      password
    );
    yield call(getSnapshotFromUserAuth, user);
  } catch (error) {
    switch (error.code) {
      case "auth/wrong-password":
        yield put(userSignInFailed("incorrect password for email"));
        break;
      case "auth/user-not-found":
        yield put(userSignInFailed("no user associated with this email"));
        break;
      default:
        yield put(userSignInFailed("Unknown error with user sign-in"));
    }
  }
}

export function* signOutSaga() {
  try {
    yield call(signOutUser);
    yield put(userSignOutSucceeded());
  } catch (error) {
    yield put(userSignOutFailed(error));
  }
}

export function* signUpSaga(action) {
  try {
    const { email, password, displayName } = action.payload;
    const { user } = yield call(createNewAuthUser, email, password);
    yield put(userSignUpSucceeded(user, { displayName }));
  } catch (error) {
    switch (error.code) {
      case "auth/email-already-in-use":
        yield put(userSignUpFailed("Email already in use"));
        break;
      default:
        console.log(error);
        yield put(userSignUpFailed("unkown error with user sign-up"));
        break;
    }
  }
}

export function* signUpSuccessSaga(action) {
  const { user, additionalInfo } = action.payload;
  try {
    yield call(getSnapshotFromUserAuth, user, additionalInfo);
  } catch (error) {
    yield put(userSignUpFailed(error));
  }
}

export function* onCheckUserSession() {
  yield takeLatest(userActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onGoogleSignInStart() {
  yield takeLatest(userActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onEmailSignInStart() {
  yield takeLatest(userActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onSignOutStart() {
  yield takeLatest(userActionTypes.SIGN_OUT_START, signOutSaga);
}

export function* onSignUpStart() {
  yield takeLatest(userActionTypes.SIGN_UP_START, signUpSaga);
}

export function* onSignUpSuccess() {
  yield takeLatest(userActionTypes.SIGN_UP_SUCCESS, signUpSuccessSaga);
}

export function* userSaga() {
  yield all([
    call(onCheckUserSession),
    call(onGoogleSignInStart),
    call(onEmailSignInStart),
    call(onSignOutStart),
    call(onSignUpStart),
    call(onSignUpSuccess),
  ]);
}
