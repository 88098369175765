import { useDispatch, useSelector } from "react-redux";
import { addProductToCartItems } from "../../state/cart/cart.actions";
import { selectCartItems } from "../../state/cart/cart.selector";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button";

import "./product-card.scss";

const ProductCard = ({ product }) => {
  const { name, price, imageUrl } = product;
  const dispatch = useDispatch();
  const cartItems = useSelector(selectCartItems);

  const addProductToCart = () => {
    dispatch(addProductToCartItems(cartItems, product));
  };

  return (
    <div className="product-card-container">
      <img src={imageUrl} alt={`product named ${name}`} />
      <div className="footer">
        <span className="name">{name}</span>
        <span className="price">${price}</span>
      </div>
      <Button
        buttonType={BUTTON_TYPE_CLASSES.inverted}
        onClick={addProductToCart}
      >
        Add to Cart
      </Button>
    </div>
  );
};

export default ProductCard;
