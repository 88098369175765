import { createAction } from "../../utils/reducer";
import { cartActions } from "./cart.types";

const addProductToThisCartItems = (cartItems, product) => {
  const existingItem = cartItems.find((item) => item.id === product.id);
  if (existingItem !== undefined) {
    // He does it this way to avoid mutating the existing item, to make the function pure
    return cartItems.map((item) => {
      return item.id === product.id
        ? { ...item, quantity: item.quantity + 1 }
        : item;
    });
  } else {
    return [...cartItems, { ...product, quantity: 1 }];
  }
};

const removeProductFromThisCartItems = (cartItems, product) => {
  const existingItem = cartItems.find((item) => item.id === product.id);
  if (existingItem === undefined) {
    return cartItems;
  }

  const newQuantity = existingItem.quantity - 1;
  if (newQuantity <= 0) {
    return cartItems.filter((item) => item.id !== product.id);
  }

  return cartItems.map((item) => {
    return item.id === product.id ? { ...item, quantity: newQuantity } : item;
  });
};

const clearProductFromThisCartItems = (cartItems, product) =>
  cartItems.filter((item) => item.id !== product.id);

export const setCartIsOpen = (cartIsOpen) =>
  createAction(cartActions.SET_CART_IS_OPEN, cartIsOpen);

export const addProductToCartItems = (cartItems, product) => {
  const newCartItems = addProductToThisCartItems(cartItems, product);
  return createAction(cartActions.SET_CART_ITEMS, newCartItems);
};

export const removeProductFromCartItems = (cartItems, product) => {
  const newCartItems = removeProductFromThisCartItems(cartItems, product);
  return createAction(cartActions.SET_CART_ITEMS, newCartItems);
};

export const clearProductFromCartItems = (cartItems, product) => {
  const newCartItems = clearProductFromThisCartItems(cartItems, product);
  return createAction(cartActions.SET_CART_ITEMS, newCartItems);
};
