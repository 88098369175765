import "./form-error.scss";

const FormError = ({ errorText }) => {
  return (
    <div className="form-error-container">
      <p className="form-error">{errorText}</p>
    </div>
  );
};

export default FormError;
