import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectSignUpError,
  startUserSignUp,
  userSignUpFailed,
} from "../../state/user/user.public";

import FormInput from "../form-input/form-input";
import FormError from "../form-error/form-error";
import Button, { BUTTON_TYPE_CLASSES } from "../button/button";

import "./sign-up-form.scss";

const defaultFormFields = {
  displayName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const SignUpForm = () => {
  const dispatch = useDispatch();
  const formError = useSelector(selectSignUpError);
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { displayName, email, password, confirmPassword } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const { displayName, email, password, confirmPassword } = formFields;
    if (password !== confirmPassword) {
      dispatch(userSignUpFailed("Passwords do not match"));
    } else {
      dispatch(startUserSignUp(email, password, displayName));
      resetFormFields();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className="sign-up-container">
      <h2>I do not have an account</h2>
      <span>Sign-up with your email address and password</span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Display Name"
          required
          type="text"
          name="displayName"
          value={displayName}
          onChange={handleChange}
        />
        <FormInput
          label="Email Address"
          required
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
        />
        <FormInput
          label="Password"
          required
          type="password"
          name="password"
          minLength="6"
          value={password}
          onChange={handleChange}
        />
        <FormInput
          label="Confirm Password"
          required
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
        />
        <FormError errorText={formError} />
        <div className="buttons-container">
          <Button type="submit">Sign Up</Button>
          <Button
            buttonType={BUTTON_TYPE_CLASSES.secondary}
            onClick={resetFormFields}
          >
            Reset
          </Button>
          `{" "}
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
